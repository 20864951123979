<template>
  <div class="aside-menu">
    <el-menu
      unique-opened
      :default-openeds="openMenuArray"
      :default-active="$route.path"
      background-color="#272B38"
      text-color="#C0C4CE"
      active-text-color="#fff"
      router
      @select="handleOpen"
    >
      <div>
        <template v-for="item in menuList">
          <side-menu-item v-if="item.children && item.children.length" :parent-item="item"></side-menu-item>
          <!-- 没有子菜单 -->
          <el-menu-item v-else :index="item.path" :key="item.id">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </el-menu-item>
        </template>
      </div>
    </el-menu>
  </div>
</template>

<script>
import store from '@/utils/store';
import SideMenuItem from './AsideMenuItem';

export default {
  components: {
    SideMenuItem
  },
  props: {
    menuType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      allMenu: [],
      haveAllMenu: [],
      openMenuArray: [],
      menuScreenList: [
        {
          id: '1',
          name: '个人工作台',
          icon: 'iconfont icon-caidan1-1gerengongzuotai',
          code: 'personalWorkbench',
          children: [
            {
              id: '1-1',
              name: '项目概括',
              path: '/personalWork',
              code: 'personalWork'
            }
            // {
            //   id: '1-2',
            //   name: '我的消息',
            //   path: '',
            //    code: '',
            // }
          ]
        },
        {
          id: '2',
          name: '相关文件',
          icon: 'iconfont icon-caidan1-2xiangguanwenjian',
          code: 'relateFile',
          children: [
            {
              id: '2-1',
              name: '文件列表',
              path: '/relateFile/1',
              code: 'relateFile1'
            }
          ]
        },
        {
          id: '3',
          name: '其他手续',
          icon: 'iconfont icon-caidan1-3qitashouxu',
          code: 'OtherProcess',
          children: [
            {
              id: '3-1',
              name: '用地名称',
              path: '/otherProcedures/2',
              code: 'otherProcedures'
            },
            {
              id: '3-2',
              name: '土地类文件',
              path: '/landFile/3',
              code: 'landFile'
            },
            {
              id: '3-3',
              name: '其他文件',
              path: '/otherFile/4',
              code: 'otherFile'
            }
          ]
        },
        {
          id: '4',
          name: '招投标管理',
          icon: 'iconfont icon-caidan1-4zhaotoubiaoguanli',
          code: 'biddingMana',
          children: [
            {
              id: '4-1',
              name: '监理招投标管理',
              path: '/biddingMana/5',
              code: 'biddingMana5'
            },
            {
              id: '4-2',
              name: '设计招投标管理',
              path: '/designBiddingMana/6',
              code: 'designBiddingMana'
            },
            {
              id: '4-3',
              name: '施工招投标管理',
              path: '/buildBiddingMana/7',
              code: 'buildBiddingMana'
            },
            {
              id: '4-4',
              name: '广告策划招投标管理',
              path: '/advertiseBiddingMana/8',
              code: 'advertiseBiddingMana'
            },
            {
              id: '4-5',
              name: '其他招投标管理',
              path: '/otherBiddingMana/9',
              code: 'otherBiddingMana'
            }
          ]
        },
        {
          id: '5',
          name: '证件管理',
          icon: 'iconfont icon-caidan1-5zhengjianguanli',
          code: 'certificatesMana',
          children: [
            {
              id: '5-1',
              name: '人员证件',
              path: '/certificatesMana/10',
              code: 'certificatesMana10'
            },
            {
              id: '5-2',
              name: '单位证件',
              path: '/unitCertificate/11',
              code: 'unitCertificate'
            }
          ]
        },
        {
          id: '6',
          name: '设计管理',
          icon: 'iconfont icon-caidan1-6jishuguanli',
          code: 'technologyMana',
          children: [
            {
              id: '6-1',
              name: '工程勘测',
              path: '/technologyMana/12',
              code: 'technologyMana12'
            },
            {
              id: '6-2',
              name: '概念设计',
              path: '/conceptDesign/13',
              code: 'conceptDesign'
            },
            {
              id: '6-3',
              name: '方案设计',
              path: '/projectDesign/14',
              code: 'projectDesign'
            },
            {
              id: '6-4',
              name: '初步设计',
              path: '/initialDesign/15',
              code: 'initialDesign'
            },
            {
              id: '6-5',
              name: '施工图设计',
              path: '/cad/16',
              code: 'cad'
            }
          ]
        },
        {
          id: '7',
          name: '施工过程管理',
          icon: 'iconfont icon-caidan1-7shigongguochengguanli',
          code: 'constructMana',
          children: [
            {
              id: '7-1',
              name: '工程准备',
              path: '/constructMana/17',
              code: 'constructMana17'
            },
            {
              id: '7-2',
              name: '基础部分',
              path: '/basicPart/18',
              code: 'basicPart'
            },
            {
              id: '7-3',
              name: '人防部分',
              path: '/defensePart/19',
              code: 'defensePart'
            },
            {
              id: '7-4',
              name: '主体部分',
              path: '/mainPart/20',
              code: 'mainPart'
            },
            {
              id: '7-5',
              name: '施工原材料质量保证',
              path: '/qualityAssurance/21',
              code: 'qualityAssurance'
            },
            {
              id: '7-6',
              name: '水、暖、煤、卫部分',
              path: '/lifePart/22',
              code: 'lifePart'
            },
            {
              id: '7-7',
              name: '电气（强弱）部分',
              path: '/electricalPart/23',
              code: 'electricalPart'
            },
            {
              id: '7-8',
              name: '智能化部分',
              path: '/intelligentPart/24',
              code: 'intelligentPart'
            },
            {
              id: '7-9',
              name: '电梯部分',
              path: '/elevatorPart/25',
              code: 'elevatorPart'
            },
            {
              id: '7-10',
              name: '设备安装部分',
              path: '/installPart/26',
              code: 'installPart'
            },
            {
              id: '7-11',
              name: '绿化工程部分',
              path: '/greeningWorks/27',
              code: 'greeningWorks'
            },
            {
              id: '7-12',
              name: '幕墙工程部分',
              path: '/curtainWall/28',
              code: 'curtainWall'
            },
            {
              id: '7-13',
              name: '设计管理',
              path: '/designMana/29',
              code: 'designMana'
            },
            {
              id: '7-14',
              name: '监理管理',
              path: '/supervisorMana/30',
              code: 'supervisorMana'
            },
            {
              id: '7-15',
              name: '施工管理',
              path: '/buildMana/31',
              code: 'buildMana'
            }
          ]
        },
        {
          id: '8',
          name: '成本管理',
          icon: 'iconfont icon-caidan1-8chengbenguanli',
          code: 'costMana',
          children: [
            {
              id: '8-1',
              name: '合同文件',
              path: '/costMana/32',
              code: 'costMana32'
            },
            {
              id: '8-2',
              name: '预算文件',
              path: '/budgetFile/33',
              code: 'budgetFile'
            },
            {
              id: '8-3',
              name: '工程合同进度付款',
              path: '/progressPayment/34',
              code: 'progressPayment'
            },
            {
              id: '8-4',
              name: '工程决算文件',
              path: '/finalAccountsFile/35',
              code: 'finalAccountsFile'
            }
          ]
        },
        {
          id: '9',
          name: '竣工验收管理',
          icon: 'iconfont icon-caidan1-9jungongyanshouguanli',
          code: 'completionMana',
          children: [
            {
              id: '9-1',
              name: '竣工验收文件',
              path: '/completionMana/36',
              code: 'completionMana36'
            },
            {
              id: '9-2',
              name: '竣工图',
              path: '/builtDrawing/37',
              code: 'builtDrawing'
            },
            {
              id: '9-3',
              name: '工程图像',
              path: '/engineeringImg/38',
              code: 'engineeringImg'
            },
            {
              id: '9-4',
              name: '音像资料',
              path: '/audioPaper/39',
              code: 'audioPaper'
            },
            {
              id: '9-5',
              name: '工程获奖',
              path: '/engineeringAward/40',
              code: 'engineeringAward'
            }
          ]
        },
        {
          id: '10',
          name: '项目质量管理',
          icon: 'iconfont icon-caidan1-10xiangmuzhiliangguanli',
          code: 'qualityMana',
          children: [
            {
              id: '10-1',
              name: '质量巡检列表',
              path: '/qualityInspection',
              code: 'qualityInspection'
            }
          ]
        },
        {
          id: '11',
          name: '项目安全管理',
          icon: 'iconfont icon-caidan1-11xiangmuanquanguanli',
          code: 'safetyMana',
          children: [
            {
              id: '11-1',
              name: '安全巡检列表',
              path: '/safetyPatrol',
              code: 'safetyPatrol'
            }
          ]
        },
        {
          id: '12',
          name: '合同管理',
          icon: 'iconfont icon-caidan1-2xiangguanwenjian',
          code: 'contractMana',
          children: [
            {
              id: '12-1',
              name: '合同列表',
              path: '/contractList',
              code: 'contractList'
            }
          ]
        },
        {
          id: '24',
          name: '项目类型管理',
          icon: 'iconfont icon-caidan4-1xiangmuleixingshezhi',
          path: '/projectType',
          code: 'projectType'
        },
        {
          id: '25',
          name: '项目状态管理',
          icon: 'iconfont icon-caidan4-2xiangmuzhuangtaiguanli',
          path: '/projectState',
          code: 'projectState'
        },
        {
          id: '26',
          name: '项目档案类型管理',
          icon: 'iconfont icon-caidan4-3xiangmudanganleixingguanli',
          path: '/projectArchives',
          code: 'projectArchives'
        },
        {
          id: '27',
          name: '质量巡检内容管理',
          icon: 'iconfont icon-caidan1-10xiangmuzhiliangguanli',
          path: '/qualityContent',
          code: 'qualityContent'
        },
        {
          id: '28',
          name: '安全巡检内容管理',
          icon: 'iconfont icon-caidan1-11xiangmuanquanguanli',
          path: '/safetyContent',
          code: 'safetyContent'
        },
        {
          id: '29',
          name: '合同类型管理',
          icon: 'iconfont icon-caidan1-2xiangguanwenjian',
          path: '/contractTypeMana',
          code: 'contractTypeMana'
        },
        {
          id: '21',
          name: '相关方列表',
          icon: 'iconfont icon-caidan2-1xiangguanfangliebiao',
          path: '/interestedList',
          code: 'interestedList'
        },
        {
          id: '22',
          name: '相关方类型管理',
          icon: 'iconfont icon-caidan2-2xiangguanfangleixingguanli',
          path: '/interestedType',
          code: 'interestedType'
        },
        {
          id: '23',
          name: '相关方等级管理',
          icon: 'iconfont icon-caidan2-3xiangguanfangdengjiguanli',
          path: '/interestedGrade',
          code: 'interestedGrade'
        },
        {
          id: '14',
          name: '用户管理',
          icon: 'iconfont icon-caidan3-1yonghuguanli',
          path: '/userMana',
          code: 'userMana'
        },
        {
          id: '15',
          name: '角色管理',
          icon: 'iconfont icon-caidan3-2jiaoseguanli',
          path: '/roleMana',
          code: 'roleMana'
        },
        {
          id: '16',
          name: '部门管理',
          icon: 'iconfont icon-caidan3-3bumenguanli',
          path: '/deptMana',
          code: 'deptMana'
        },
        {
          id: '17',
          name: '职位管理',
          icon: 'iconfont icon-caidan3-4zhiweiguanli',
          path: '/positionMana',
          code: 'positionMana'
        },
        {
          id: '18',
          name: '菜单管理',
          icon: 'iconfont icon-caidan3-5caidanguanli',
          path: '/menuMana',
          code: 'menuMana'
        },
        {
          id: '19',
          name: '字典管理',
          icon: 'iconfont icon-caidan3-6zidianguanli',
          path: '/dictionaryMana',
          code: 'dictionaryMana'
        },
        {
          id: '20',
          name: '登录日志',
          icon: 'iconfont icon-caidan3-7denglurizhi',
          path: '/loginLog',
          code: 'loginLog'
        }
      ]
    };
  },
  computed: {
    menuList() {
      this.menuScreenList = this.$options.data().menuScreenList;
      this.allMenu = store.getSession('allMenu');
      this.whichTab(this.menuType);
      this.haveAllMenu = this.setMenuList(this.currentTab).filter(item => item.show);
      this.isHaveMenu(this.menuScreenList);
      let menuCode = this.haveAllMenu.map(item => item.code);
      this.menuScreenList = this.filterMenu(this.menuScreenList, menuCode);
      let currentMenuId = store.getSession('currentMenuId');
      let currentPath = store.getSession('currentPath');
      if (currentPath) {
        this.$router.push({ path: currentPath, query: { id: currentMenuId } });
      } else {
        let child = this.menuScreenList[0].children;
        if (child) {
          this.$router.push({ path: child[0].path });
        } else {
          this.$router.push({ path: this.menuScreenList[0].path });
        }
      }
      // console.log(this.menuScreenList);
      return this.menuScreenList;
    }
  },
  methods: {
    whichTab(tab) {
      this.currentTab = [];
      this.allMenu.forEach(item => {
        if (item.sort === tab) {
          this.currentTab = item.children;
        }
      });
    },
    isHaveMenu(list) {
      list.forEach(have => {
        this.haveAllMenu.forEach(item => {
          if (item.code === have.code) {
            have.id = item.id;
          }
        });
        if (have.children) this.isHaveMenu(have.children);
      });
    },
    setMenuList(list) {
      return list.reduce((prev, { children = [], ...item }) => {
        return prev.concat([item], this.setMenuList(children));
      }, []);
    },
    filterMenu(menuList, menuCode) {
      return menuList
        .filter(item => {
          return menuCode.indexOf(item.code) > -1;
        })
        .map(item => {
          item = Object.assign({}, item);
          if (item.children) {
            item.children = this.filterMenu(item.children, menuCode);
          }
          return item;
        });
    },
    handleOpen(path) {
      store.setSession('currentPath', path);
    }
  }
};
</script>
<style lang="less" scoped>
.aside-menu {
  width: 100%;
}
/deep/ .el-menu-item.is-active {
  background-color: #409dff !important;
}
.el-menu {
  border: none;
  padding-top: 10px;
}
</style>
